body {
    margin: 0;
    height: 100%;
    line-height: 1.3 !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Pacifico',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    --PRIMARY-COLOR: rgba(19, 41, 150, 1);
    --PRIMARY-COLOR-07: rgba(19, 41, 150, 0.7);
    --PRIMARY-COLOR-05: rgba(19, 41, 150, 0.5);
    --PRIMARY-COLOR-03: rgba(19, 41, 150, 0.3);
    --SECONDARY-COLOR: rgba(253, 216, 3, 1);
    --SECONDARY-COLOR-07: rgba(253, 216, 3, 0.7);
    --SECONDARY-COLOR-05: rgba(253, 216, 3, 0.5);
    --SECONDARY-COLOR-03: rgba(253, 216, 3, 0.3);
    --NEUTRAL-COLOR: rgba(248, 248, 248, 1);
    --NEUTRAL-COLOR-07: rgba(248, 248, 248, 0.7);
    --NEUTRAL-COLOR-03: rgba(248, 248, 248, 0.3);

    --GREY-COLOR: rgba(199, 199, 199, 1);

    --WHITE-COLOR: rgba(255, 255, 255, 1);
    --WHITE-COLOR-07: rgba(255, 255, 255, 0.7);
    --WHITE-COLOR-03: rgba(255, 255, 255, 0.3);

    --ERROR-COLOR: #961329;
    --WARNING-COLOR: #fd9a03;
    --SUCCESS-COLOR: #139680;

    --BLUE-FLAG: #002b7f;
    --YELLOW-FLAG: #fcd116;
    --RED-FLAG: #ce1126;

    --LINEAR-GRADIENT: linear-gradient(90deg, var(--PRIMARY-COLOR) 0%, var(--NEUTRAL-COLOR-03) 50%, var(--PRIMARY-COLOR) 100%);
    /*--LINEAR-GRADIENT: linear-gradient(90deg, var(--PRIMARY-COLOR) 38%, var(--NEUTRAL-COLOR-03) 100%);*/

    --PADDING-NONE: 0;
    --PADDING-XXS: 1px;
    --PADDING-XS: 3px;
    --PADDING-S: 6px;
    --PADDING-M: 12px;
    --PADDING-L: 18px;
    --PADDING-XL: 24px;
    --PADDING-XXL: 36px;

    --TRANSITION: 0.5s;

    --BREAK-POINT-SM: 576px;
    --BREAK-POINT-MD: 768px;
    --BREAK-POINT-LG: 992px;
    --BREAK-POINT-XL: 1200px;

}

pre {
    line-height: 1.3 !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Pacifico',
    sans-serif !important;
    font-style: normal;
    display: flex;
    white-space: pre-wrap;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.display-none {
    display: none;
}

.display-flex {
    display: flex;
}

.display-grid {
    display: grid;
}

.column {
    flex-direction: column;
}

.justify-center {
    justify-content: center;
}

.justify-space-between {
    justify-content: space-between;
}

.justify-space-around {
    justify-content: space-around;
}

.justify-end {
    justify-content: flex-end;
}

.align-center {
    align-items: center !important;
}

.padding-small {
    padding: var(--PADDING-S) !important;
}

.padding-large {
    padding: var(--PADDING-L) !important;
}

.padding-x-large {
    padding: var(--PADDING-XL) !important;
}

.padding-xx-large {
    padding: var(--PADDING-XXL) !important;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-underline {
    text-decoration: underline;
}

.font-bold {
    font-weight: bold;
}

.font-italic {
    font-style: italic;
}

.font-smaller {
    font-size: smaller;
}

.font-small {
    font-size: small;
}

.font-x-small {
    font-size: x-small;
}

.font-larger {
    font-size: larger;
}

.font-xx-large {
    font-size: xx-large;
}

.font-x-large {
    font-size: x-large;
}

.full-width {
    width: 100%;
}

a {
    color: var(--NEUTRAL-COLOR) !important;
    cursor: pointer;
}

a:hover {
    color: var(--SECONDARY-COLOR) !important;
    text-decoration: none !important;
}

.table td {
    border-top: 1px solid var(--NEUTRAL-COLOR) !important;
}

.table th {
    border-style: none !important;
}

div[class^="col"] {
    padding: var(--PADDING-NONE) !important;
}

.container-fluid {
    padding: var(--PADDING-NONE) !important;
}

.row {
    padding: var(--PADDING-NONE) !important;
    margin: 0 !important;
}

.nav-link {
    color: var(--NEUTRAL-COLOR);
}

.nav-link:hover {
    color: var(--SECONDARY-COLOR);
}

.no-padding {
    padding: var(--PADDING-NONE) !important;
}

.cursor-pointer {
    cursor: pointer;
}

.form-label {
    padding: var(--PADDING-NONE) !important;
}

input, select {
    border: 1px solid var(--PRIMARY-COLOR) !important;
    padding: 6px 12px!important;
    border-radius: 0.25rem;
}
input.MuiInputBase-input.MuiInput-input{
    height: 24px!important;
}

.MuiFormControl-root.MuiTextField-root{
    width: 100%;
}

.field-error {
    color: var(--ERROR-COLOR);
    font-size: small;
}

.light-label {
    color: var(--PRIMARY-COLOR);
    font-size: smaller;
}

.color-error {
    color: var(--ERROR-COLOR);
}

.color-success {
    color: var(--SUCCESS-COLOR);
}

.color-warning {
    color: var(--WARNING-COLOR);
}

.color-primary {
    color: var(--PRIMARY-COLOR) !important;
}

.color-primary-03 {
    color: var(--PRIMARY-COLOR-03);
}

.color-neutral {
    color: var(--NEUTRAL-COLOR);
}

.no-margin {
    margin: 0 !important;
}