@import url("../components/Event/style.css");
/*EVENT component style */
@import url("../components/EventSidebar/style.css");
/*EVENT SIDE BAR component style */
@import url("../components/Navbar/style.css");
/*NAVBAR component style */
@import url("../components/EventInfoBanner/style.css");
/*EVENT BANNER component style */
@import url("../components/EventsGrid/style.css");
/*EVENT GRID component style */
@import url("../pages/eventDetails/style.css");
/*EVENT DETAILS CONTENT page style */
@import url("../pages/home/style.css");
/*HOME page style */
@import url("../pages/profile_admin/style.css");
/*USER PROFILE page style */
@import url("../pages/admin_pages/style.css");
/*ADMIN PAGES style */
@import url("../pages/profile_user/style.css");
/*USER PROFILE style */
/*REGISTER PAGE style */
@import url("../pages/register/style.css");

.container-with-flag {
    border-left: 3px solid;
    border-image: linear-gradient(var(--BLUE-FLAG), var(--YELLOW-FLAG), var(--RED-FLAG)) 3;
    border-radius: 0 5px 5px 0;
}

.page-container {
    height: 100%;
    width: 100%;
    padding: var(--PADDING-NONE) !important;
    /*position:relative;*/
    display: flex !important;
    flex-direction: column !important;
}

.home-page-container {
    height: 100%;
    margin-left: 20%;
    margin-right: 20%;
    padding: var(--PADDING-NONE) !important;
    padding-top: var(--PADDING-XL) !important;
    display: flex !important;
    flex-direction: column !important;
}

@media screen and (max-width: 768px) {
    .home-page-container {
        height: 100%;
        margin-left: var(--PADDING-S);
        margin-right: var(--PADDING-S);
        padding: var(--PADDING-NONE) !important;
        padding-top: var(--PADDING-M) !important;
        display: flex !important;
        flex-direction: column !important;
    }
}

.page-title {
    padding: var(--PADDING-XXL) var(--PADDING-NONE) var(--PADDING-XXL) var(--PADDING-NONE);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    color: var(--PRIMARY-COLOR);
    letter-spacing: 2px;
    font-weight: bold;
    font-size: x-large;
}

.page-title-main {
    text-decoration: underline var(--SECONDARY-COLOR);
}

.page-title-subtitle {
    font-size: smaller;
    font-weight: 400;
}

.footer-root-col {
    background: var(--LINEAR-GRADIENT);
    height: 20px;
    padding: var(--PADDING-NONE) var(--PADDING-S) !important;
    display: flex;
    align-items: center;
}

.profile-container {
    margin-top: var(--PADDING-XXL);
    padding: var(--PADDING-L) 100px var(--PADDING-L) var(--PADDING-L) !important;

    color: var(--PRIMARY-COLOR);
    background-color: var(--NEUTRAL-COLOR);
    box-shadow: 5px 5px 10px 0.5PX var(--PRIMARY-COLOR-07);
    position: relative;
}

.contact-container {
    padding: var(--PADDING-L) var(--PADDING-NONE);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    color: var(--PRIMARY-COLOR);
    background-color: var(--NEUTRAL-COLOR);
    box-shadow: 5px 5px 10px 0.5PX var(--PRIMARY-COLOR-07);
}

.contact-label {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align: right;
    border-right: 1px solid var(--NEUTRAL-COLOR);
}

.contact-value {
    display: flex;
    align-items: center;
    font-weight: bold;
}

.page-container a {
    color: var(--PRIMARY-COLOR) !important;
    cursor: pointer;
    font-weight: bold;
}

.page-container a:hover {
    color: var(--PRIMARY-COLOR-07) !important;
    cursor: pointer;
    font-weight: bold;
}

a > .contact-value {
    color: var(--PRIMARY-COLOR);
}

a > .contact-value:hover {
    color: var(--SECONDARY-COLOR);
}

.ranking-table-container {
    padding: var(--PADDING-M) var(--PADDING-NONE);
    margin: 15px 0;
    color: var(--PRIMARY-COLOR);
    background-color: var(--NEUTRAL-COLOR);
    box-shadow: 5px 5px 10px 0.5PX var(--PRIMARY-COLOR-07);
}

table {
    color: var(--PRIMARY-COLOR) !important;
}

/*tr:hover{*/
/*color: var(--PRIMARY-COLOR) !important;*/
/*background-color: var(--SECONDARY-COLOR-03) !important;*/
/*}*/

.table-header-row {
    color: var(--PRIMARY-COLOR) !important;
}

.table-header-row:hover {
    color: var(--PRIMARY-COLOR) !important;
    background-color: var(--NEUTRAL-COLOR-03) !important;
}

.custom-table {
    background-color: var(--WHITE-COLOR-07) !important;
}

.collapsed-table-row {
    padding-top: 5px !important;
    border-bottom: 2px solid var(--PRIMARY-COLOR-03);
}

.collapsed-table-data-row {
    display: grid;
    grid-template-columns: 1fr 2fr;
    border-bottom: 1px solid var(--PRIMARY-COLOR-03);
    align-items: center;

}

.collapsed-table-data-row > div {
    padding: 5px 5px 5px 3px !important;
}

.ranking-table {

}

.total-no-table-items {
    font-size: small;
    text-align: right;
    padding-top: var(--PADDING-M);
    color: var(--PRIMARY-COLOR);
}

.label {
    font-size: smaller;
    color: var(--PRIMARY-COLOR-07);
    padding-bottom: 2px;
}

.input-row-container {
    padding: var(--PADDING-S) var(--PADDING-NONE) !important;
    display: flex;
    align-items: center;
}

.facebook-link {
    color: var(--PRIMARY-COLOR) !important;
    padding: var(--PADDING-L);
}

.facebook-link:hover {
    color: var(--PRIMARY-COLOR) !important;
    text-shadow: 1px 1px 1px var(--PRIMARY-COLOR);
}

.submit-btn {
    background-color: var(--PRIMARY-COLOR-07) !important;
    border-color: var(--PRIMARY-COLOR-03) !important;
    margin-right: var(--PADDING-XS) !important;
    box-shadow: 2px 2px 3px var(--PRIMARY-COLOR-05);
    margin-top: 5px;
    margin-bottom: 5px;
}

.submit-btn:focus {
    box-shadow: 2px 2px 3px var(--PRIMARY-COLOR) !important;
}

.submit-btn:hover {
    box-shadow: 2px 2px 3px var(--PRIMARY-COLOR-07) !important;
}

.submit-btn svg {
    color: var(--NEUTRAL-COLOR);
    font-weight: bold;
    font-size: larger;
}

.back-btn {
    background-color: var(--NEUTRAL-COLOR) !important;
    border-color: var(--PRIMARY-COLOR) !important;
    color: var(--PRIMARY-COLOR) !important;
    margin-right: var(--PADDING-XS) !important;
    margin-top: 5px;
    margin-bottom: 5px;
}

.back-btn:hover {
    box-shadow: 2px 2px 3px var(--PRIMARY-COLOR-07);
}

.add-btn {
    background-color: var(--NEUTRAL-COLOR) !important;
    border: 1px solid var(--PRIMARY-COLOR-03) !important;
    color: var(--PRIMARY-COLOR) !important;
    box-shadow: 2px 2px 3px var(--PRIMARY-COLOR-05);
    margin-top: 5px;
    margin-bottom: 5px;
    padding: var(--PADDING-S);
    margin-right: var(--PADDING-XS) !important;
}

.add-btn:hover {
    box-shadow: 2px 2px 3px var(--PRIMARY-COLOR-07);
}

.remove-btn {
    background-color: var(--WHITE-COLOR) !important;
    border: none !important;
    color: var(--ERROR-COLOR) !important;
    margin-right: var(--PADDING-XS) !important;
    padding: var(--PADDING-XS) !important;
    margin-top: 5px;
    margin-bottom: 5px;
}

.remove-btn:hover {
    background-color: var(--NEUTRAL-COLOR) !important;
    border-color: var(--ERROR-COLOR) !important;
    color: var(--ERROR-COLOR) !important;
    /*box-shadow: 2px 2px 3px var(--ERROR-COLOR);*/
}

.download-btn {
    background-color: var(--PRIMARY-COLOR-07) !important;
    border-color: var(--PRIMARY-COLOR-03) !important;
    margin-right: var(--PADDING-XS) !important;
    box-shadow: 2px 2px 3px var(--PRIMARY-COLOR-05);
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex !important;
    align-items: center !important;
}

.download-btn:hover {
    box-shadow: 2px 2px 3px var(--PRIMARY-COLOR-07) !important;
}

.download-btn:focus {
    box-shadow: 2px 2px 3px var(--PRIMARY-COLOR) !important;
}

.breadcrumb-item a {
    color: var(--PRIMARY-COLOR) !important;
}

.breadcrumb-item a:hover {
    color: var(--PRIMARY-COLOR-07) !important;
}

.popover-item {
    cursor: pointer;
    padding: var(--PADDING-S);

}

.popover-item:hover {
    color: var(--PRIMARY-COLOR);

}

.loader-container {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: var(--PADDING-M);
    padding-bottom: var(--PADDING-M);
}

.spinner-border {
    color: var(--PRIMARY-COLOR) !important;
}

.input-container {
    padding-right: var(--PADDING-M) !important;
    padding-left: 0 !important;
}

.checkbox-container {
    margin-top: 20px;
    display: flex;
    align-items: center;
    padding: var(--PADDING-S) var(--PADDING-M) var(--PADDING-S) 0;
}

.checkbox-container .form-check {
    padding-right: var(--PADDING-S);
}

.checkbox-container .form-group {
    margin: 0 !important;
}

.checkbox-container_withLabel {
    /*margin-top: 20px;*/
    display: flex;
    align-items: center;
    padding: var(--PADDING-S) var(--PADDING-M) var(--PADDING-S) 0;
}

.checkbox-container_withLabel .form-group {
    margin: 0 !important;
}

.button-container {
    margin-top: 20px;
    display: grid;
    align-items: center;
    padding: var(--PADDING-S) var(--PADDING-M) var(--PADDING-S) 0;
}

input[type="checkbox" i] {
    background-color: var(--PRIMARY-COLOR) !important;
}

.notification-success {
    border-bottom: var(--SUCCESS-COLOR) 2px solid !important;
    color: var(--SUCCESS-COLOR) !important
    /*box-shadow: 2px 2px 3px green!important;*/
    /*background-color: lightgreen !important;*/
}

.notification-warning {
    border-bottom: var(--WARNING-COLOR) 2px solid !important;
    color: var(--WARNING-COLOR) !important
}

.notification-error {
    border-bottom: var(--ERROR-COLOR) 2px solid !important;
    color: var(--ERROR-COLOR) !important
}

.notifications-container {
    right: 3%;
    position: fixed !important;
    bottom: 5%;
    z-index: 3000;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.notification-toast {
    border: none !important;
}

.notification-segment {
    width: 300px;
    padding: var(--PADDING-S) !important;
    padding-left: 20px !important;

}

.notification-content {
    position: relative;
    margin-bottom: 10px;
}

.notification-icon {
    position: absolute;
    left: -15px !important;
    top: 6px !important;
    z-index: 1000;
}

.notification-message-green {
    /*color: var(--PRIMARY-COLOR);*/
}

.no-data-message {
    font-style: italic;
    width: 100%;
    text-align: center;
    color: var(--PRIMARY-COLOR);
    font-size: smaller;
    padding: var(--PADDING-L);
    background-color: var(--NEUTRAL-COLOR);
}

.filter-container {
    padding-top: var(--PADDING-M);
    padding-bottom: var(--PADDING-L);
}

.modal-header {
    padding-top: var(--PADDING-S) !important;
    padding-bottom: var(--PADDING-S) !important;
}

.history-modal-container {
    max-height: 300px;
    overflow-y: auto;
}

.history-modal-container::-webkit-scrollbar {
    width: 6px;
}

.history-modal-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.history-modal-container::-webkit-scrollbar-thumb {
    background-color: darkgrey;
}

.modal-subtitle {
    font-size: small;
    text-transform: uppercase;
    color: var(--GREY-COLOR);
}

.team-status-2 {
    font-style: italic
}

.team-status-3 {
    color: var(--SUCCESS-COLOR)
}

.team-status-4 {
    color: black
}

.preview-docs-container {
    padding: 0 !important;
    height: 100vh !important;
    margin-top: 15px;
    margin-bottom: 30px;
    border: 3px solid var(--PRIMARY-COLOR-05) !important;
}

.choose-doc-button {
    display: grid;
    grid-template-columns: 60px auto;
    align-items: center;
    padding: var(--PADDING-L);
    color: var(--PRIMARY-COLOR);
    cursor: pointer;
    background-color: var(--NEUTRAL-COLOR-07);
    border-radius: 10px;
    border: 1px solid var(--PRIMARY-COLOR-03);
    margin: 5px
}

.choose-doc-button-active {
    display: grid;
    grid-template-columns: 60px auto;
    align-items: center;
    padding: var(--PADDING-L);
    color: var(--PRIMARY-COLOR);
    cursor: pointer;
    background-color: var(--PRIMARY-COLOR-03);
    border-radius: 10px;
    border: 2px solid var(--PRIMARY-COLOR);
    margin: 4px;
}

@media screen and (max-width: 768px) {
    .page-container {
        /*padding-right: 10px !important;*/
        /*padding-left: 10px !important;*/
    }

    .page-title {
        padding: var(--PADDING-XL) var(--PADDING-NONE) var(--PADDING-S) var(--PADDING-NONE);
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: var(--PRIMARY-COLOR);
        letter-spacing: 1px;
        font-weight: bold;
        font-size: large;
    }

    .profile-container {
        overflow: hidden;
        padding: var(--PADDING-L) var(--PADDING-L) var(--PADDING-L) var(--PADDING-L) !important;
    }

    .contact-label {
        justify-content: flex-start;
        text-align: left;
    }

    .contact-value {
        text-align: right;
        justify-content: flex-end;
    }

}

.form-check-input {
    margin-top: 0.1rem !important;
}


