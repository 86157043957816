.description-container{
    padding: var(--PADDING-M) var(--PADDING-XXL) !important;
    text-align: center;
}

.description-logo-img-container{
    display: flex;
    justify-content: center;
}
.description-logo-img{
    height: 150px;
}
.register-back-to-login{
    padding-top: var(--PADDING-XL);
    font-size: small;
}