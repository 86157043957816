.root-container-layout {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: var(--PADDING-NONE) !important;
}

.nav-bar-container {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    min-height: 40px;
    border-bottom: 3px solid;
    background-color: var(--PRIMARY-COLOR);
    border-image: linear-gradient(to right, var(--BLUE-FLAG), var(--YELLOW-FLAG), var(--RED-FLAG)) 3;
    font-size: larger;
    z-index: 100;
}

.content-container {
    flex: 1;
    overflow: auto;
    background-image: url('../utils/texture-sand-programs.jpg');
    background-attachment: fixed;
    background-position: right bottom;
    background-size: 75% auto;
    background-repeat: no-repeat;
    height: 100%;
}

.footer {
    font-size: x-small;
    height: 20px;
    background-color: var(--PRIMARY-COLOR);
    color: var(--SECONDARY-COLOR);
    z-index: 10;
}